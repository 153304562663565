import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import rem from "../utils/rem"
import PageWrapper from "../components/pageWrapper"

const NotFoundPage = ({ data }) => (
  <Layout>
    <SEO title="404: Not found" />
    <div
      style={{
        backgroundColor: "#8799a3",
        position: "relative",
        display: "flex",
      }}
    >
      <Header siteTitle={data.site.siteMetadata.title} />
      <PageWrapper style={{ margin: `${rem(200)} auto` }}>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist...</p>
      </PageWrapper>
    </div>
  </Layout>
)

export default NotFoundPage

export const notFoundQuery = graphql`
  query NotFoundQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
